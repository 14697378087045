<template>
  <div class="windrose d-flex flex-column align-items-center">
    <div class="title-content">
      <h6 class="mb-1">{{ season_and_time }}</h6>
      <span>{{ subheader }}</span>
    </div>
    <img v-show="windrose.asset_file" :src="windrose.asset_file" alt="" id="windrose-image">
    <span class="mt-0 calm-winds-text" v-if="this.calmPercentage">calm winds: {{this.calmPercentage}}%</span>
    <div :class="scaleClass" :style="style" >
      <div class="scale-bg">
        <div class="grad-box">
          <span v-for="(value, index) in annotationStrings"
          :key="index"
          :class="gradClass(index)"
          :style="{ left: annotationPercentages[index] + '%' }"><span class="grad-label">{{ value }}</span></span>
        </div>
      </div>
      <h4 class="scale-units">{{ getPlotDescription() }}</h4>
      
    </div>
    <div class="d-flex flex-row w-100 justify-content-between align-items-end mt-0">
      <span></span>
      <a class="mt-0" href="https://help.orbitalstack.com/wind-rose-explained/" target="_blank">Learn more</a>
      <b-button  
      v-b-tooltip.hover 
      title="export windrose"  
      v-if="!downloadingAssest"
      @click="exportWindrose()" 
      variant="outline" 
      class="p-0">
        <b-icon icon='download' class="text-white" font-scale=0.9></b-icon>
      </b-button>
    </div>  
  </div>
</template>

<script>
import ColorScaleData from '@/utilities/color-scales/color';
import PlotType from '@/utilities/color-scales/plot-type';
import html2canvas from 'html2canvas';

export default {
  name: 'WindRose',
  props: {
    windrose: {
      type: Object,
      required: true
    },
    metSourceId: {
      required: false
    }
  },
  async mounted() {
    const colorLegendParameters = {
      colorLegendName: this.windrose?.color_legend_name,
      colorLegendVersion: this.windrose?.color_legend_version || 1
    };
    if (this.windrose?.color_legend_name) {
      await this.$store.dispatch('project/simulationAsset/getColorLegendByName', colorLegendParameters);
    }
  },
  computed: {
    season_and_time() {
      let season_and_time_string = this.windrose.season_filter;
      if (this.windrose.time_filter) {
        season_and_time_string = `${season_and_time_string} • ${this.windrose.time_filter}`;
      }
      return season_and_time_string;
    },
    colorScaleData() {
      return new ColorScaleData(this.plotType, this.gradientPoints, this.colorMap?.Points);
    },
    calmPercentage() {
      return this.windrose?.calm_percentage;
    },
    plotType() {
      return this.colorMap?.IndexedColors ? PlotType?.DISCRETE : PlotType?.CONTINUOUS;
    },
    gradientPoints() {
      return this.colorMap?.IndexedColors || this.colorMap?.RGBPoints;
    },
    style() {
      return { '--linear-gradient': this.colorScaleData.linearGradientString };
    },
    annotationStrings() {
      return this.colorMap?.Annotations?.filter(annotation => typeof annotation === 'string');
    },
    annotationPercentages() {
      return this.colorScaleData.cssAnnotationPercentages(this.colorMap?.Annotations);
    },
    scaleClass() {
      return this.plotType === PlotType?.DISCRETE ? 'discrete-scale scale' : 'scale';
    },
    colorScales() {
      return this.$store.getters['project/simulationAsset/colorLegend'];
    },
    colorMap() {
      return this.windrose?.color_map ? this.windrose?.color_map[0] : null;
    },
    selectedProject() {
      return this.$store.getters['project/selectedProject'];
    },
    metDataInfo() {
      if (!this.selectedProject) {
        return '';
      }

      const metSources = this.selectedProject.met_sources;
      const metSource = metSources.find(x => x.id == this.metSourceId);

      if (!metSource) {
        return '';
      }
      
      if(metSource?.station?.name) {
        return metSource.station.name;
      } else if (metSource?.custom_met_data) {
        if (metSource.custom_met_data.label) {
          return metSource.custom_met_data.label;
        } else {
          let parsedUrl = new URL(metSource.custom_met_data?.file);
          let filename = parsedUrl?.pathname.substring(parsedUrl?.pathname.lastIndexOf('/')+1); 
          return filename;
        }
      } else if (metSource.wind_data_type.startsWith('FUTURE'))  {
        let tokens = metSource.wind_data_type.split('-');
        let filename = `Future Met: ${tokens[2]}, ${tokens[1]}`;
        return filename;
      } else {
        return `modelled at ${this.selectedProject.latitude}\u00B0,${this.selectedProject.longitude}\u00B0`;
      }
    },
    subheader() {
      if(this.windrose.location) {
        return this.windrose.location;
      } else {
        return this.metDataInfo;
      }
    }
  },
  methods: {
    async exportWindrose () {
      this.downloadingAssest = true;
      new Promise((resolve) => {
        const element = document.getElementsByClassName('windrose-menu');
        resolve(html2canvas(element[0], { allowTaint: true,  useCORS: true }));
      }).then(canvas => {
        document.body.appendChild(canvas);  
        let windroseImage = document.getElementById('windrose-image');
        windroseImage.crossOrigin = 'anonymous';
        const windroseUrl = canvas.toDataURL('image/png');
        let aTag = document.createElement('a');
        aTag.href = windroseUrl; 
        aTag.download = 'windrose.png'; 
        aTag.click(); 
        document.body.removeChild(canvas);  
      });
      this.downloadingAssest = false;
    },
    gradClass(index) {
      if (index === 0) {
        return 'grad min';
      }
      if (index >= this.annotationStrings.length - 1) {
        return 'grad max';
      }
      return 'grad';
    },
    getPlotDescription() {
      return this.colorMap?.PlotDescription;
    }
  },
  data(){
    return{
      downloadingAssest: false, 
    };
  }
};
</script>

<style scoped>

.calm-winds-text {
  font-size: 80%;
}
.windrose img {
  max-width: 20em;
  max-height: 20em;
  margin: 0;
}

.title-content {
  position: absolute;
  top: 1em;
  left: 5em;
  right: 5em;
  margin: 0 auto;
  text-align: center;
}

.scale {
  width: calc(100% - 4rem);
  margin: 0 auto;
}

.scale + .scale {
  margin-top: 1rem;
}

.scale-type {
  font-size: 0.6rem;
  text-transform: uppercase;
  font-weight: 600;
  text-align: left;
  margin: 0;
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
}

.scale-type:before {
  content: '';
  display: inline-block;
  background-repeat: no-repeat;
}

.scale-horizontal:before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMC42OTMiIGhlaWdodD0iOS40ODEiIHZpZXdCb3g9IjAgMCAxMC42OTMgOS40ODEiPg0KICA8ZyBpZD0iR3JvdXBfMzI3NiIgZGF0YS1uYW1lPSJHcm91cCAzMjc2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyMTguNjkyIC0xMDEzMC4wMTkpIj4NCiAgICA8cGF0aCBpZD0iUGF0aF8yNjY2IiBkYXRhLW5hbWU9IlBhdGggMjY2NiIgZD0iTS0xMDcuNTU5LTgxLjg4bDQuNjU1LDMuMTIyLDQuNjUtMy4xMjJoMEwtMTAyLjktODVsLTQuNjUxLDMuMTIzWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExMC40NDEgMTAyMTUuNTIxKSIgZmlsbD0iI2E4YWNiMiIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMSIvPg0KICAgIDxwYXRoIGlkPSJQYXRoXzI2NjYtMiIgZGF0YS1uYW1lPSJQYXRoIDI2NjYiIGQ9Ik0tMTA3LjU1Ny03OS42NDNsNC42NTUsMy4xMjIsNC42NS0zLjEyMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExMC40NDEgMTAyMTUuNTIxKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMSIvPg0KICA8L2c+DQo8L3N2Zz4NCg==");
  width: 1rem;
  height: 0.75rem;
  background-size: 1.5em;
  margin-right: 0.125rem;
  margin-bottom: -0.125rem;
}

.scale-vertical:before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI3LjY1NCIgaGVpZ2h0PSIxMC45NCIgdmlld0JveD0iMCAwIDcuNjU0IDEwLjk0Ij4NCiAgPGcgaWQ9Ikdyb3VwXzMyNzgiIGRhdGEtbmFtZT0iR3JvdXAgMzI3OCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjE4LjE1NiAtMTAxNjEuMzMxKSI+DQogICAgPHBhdGggaWQ9IkxpbmVfNjUiIGRhdGEtbmFtZT0iTGluZSA2NSIgZD0iTS0xMDUuMjExLTQzLjc1bDQuNjUtMy4xMjNWLTUyLjVoMGwtNC42NSwzLjEyMmgwdjUuNjI0WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExMC40NDEgMTAyMTUuNTIxKSIgZmlsbD0iI2E4YWNiMiIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMSIvPg0KICAgIDxwYXRoIGlkPSJMaW5lXzY1LTIiIGRhdGEtbmFtZT0iTGluZSA2NSIgZD0iTS0xMDIuNTY1LTUzLjVsLTQuNjUsMy4xMjJoMHY1LjYyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExMC40NDEgMTAyMTUuNTIxKSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMSIvPg0KICA8L2c+DQo8L3N2Zz4NCg==");
  width: 0.75rem;
  height: 1rem;
  background-size: 1.125em;
  margin-right: 0.25rem;
  margin-bottom: -0.25rem;
}

.scale-volumetric:before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMC42OTMiIGhlaWdodD0iMTIuODciIHZpZXdCb3g9IjAgMCAxMC42OTMgMTIuODciPg0KICA8ZyBpZD0iR3JvdXBfMzI4MCIgZGF0YS1uYW1lPSJHcm91cCAzMjgwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyMTguNjc1IC0xMDE5OC42NzcpIj4NCiAgICA8Y2lyY2xlIGlkPSJFbGxpcHNlXzc1IiBkYXRhLW5hbWU9IkVsbGlwc2UgNzUiIGN4PSIyLjgxMiIgY3k9IjIuODEyIiByPSIyLjgxMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIxNi4xMzkgMTAyMDIuMjk5KSIgZmlsbD0iI2E4YWNiMiIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMSIvPg0KICAgIDxnIGlkPSJHcm91cF8zMjYwIiBkYXRhLW5hbWU9Ikdyb3VwIDMyNjAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMTAuNDQxIDEwMjE1LjUyMSkiPg0KICAgICAgPGcgaWQ9Ikdyb3VwXzEzMTkiIGRhdGEtbmFtZT0iR3JvdXAgMTMxOSI+DQogICAgICAgIDxwYXRoIGlkPSJQYXRoXzI2NjciIGRhdGEtbmFtZT0iUGF0aCAyNjY3IiBkPSJNLTEwMi44ODEtMTYuMzQ1bC00LjY1MSwzLjEyM1YtNy42bDQuNjUxLDMuMTIzLDQuNjUtMy4xMjN2LTUuNjI0WiIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMSIvPg0KICAgICAgICA8cGF0aCBpZD0iUGF0aF8yNjY2IiBkYXRhLW5hbWU9IlBhdGggMjY2NiIgZD0iTS05OC4yMzUtMTMuMjIybC00LjY1LDMuMTIyLTQuNjU1LTMuMTIyIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxIi8+DQogICAgICAgIDxsaW5lIGlkPSJMaW5lXzY1IiBkYXRhLW5hbWU9IkxpbmUgNjUiIHkyPSI1LjYyNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEwMi44ODMgLTEwLjEpIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxIi8+DQogICAgICA8L2c+DQogICAgPC9nPg0KICA8L2c+DQo8L3N2Zz4NCg==");
  width: 1rem;
  height: 1rem;
  background-size: 1.25em;
  margin-right: 0.125rem;
  margin-bottom: -0.4rem;
}

.scale-title {
  font-size: 0.9rem;
  font-weight: 600;
  text-align: center;
  margin: -2.25rem 0 0 0;
}

.scale-type + .scale-title {
  margin: -1.25rem 0 0 0;
}

.scale-units {
  font-size: 0.75rem;
  font-weight: 600;
  text-align: center;
  margin: -2rem 0 0 0;
}

.scale-bg {
  width: calc(100% - 4rem);
  height: 0.75rem;
  border: 0.063rem solid #fff;
  margin: 2.75rem auto;
  background: var(--linear-gradient);
}

.scale.discrete-scale .scale-bg {
  width: 100%;
  margin: 2.5rem 0;
}

.grad-box {
  margin: -2.125rem 0 .75rem 0;
  position: relative;
}

.grad-box > * {
  margin: 0;
}

.grad {
  position: absolute;
  padding: 0 0 0.25rem 0;
  font-size: 0.7rem;
}

.grad-label {
  position: relative;
  margin-left: -65%;
  display: block;
  font-size: 0.8em;
}

.grad.min {
  margin-left: -0.125rem;
}

.grad.max {
  margin-right: 0;
}

.grad:after {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 0.125rem;
  height: 1rem;
  background-color: #fff;
}

/* Discrete Scale styles */

.discrete-scale .grad:after {
  display: none; /* omit the ticks */
}

.discrete-scale .grad-box {
  margin: -1.5rem 0 .75rem 0; /* move the labels closer to the scale */
  position: relative;
}

.discrete-scale .grad {
  position: absolute;
  padding: 0;
  font-size: 0.7rem;
  transform: translate(-50%); /* align the label to the middle of the element */
}

.discrete-scale .grad-label {
  position: relative;
  margin-left: 0;
  display: block;
  text-align: center;
}
</style>
